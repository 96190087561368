@import "Partial/Reset.scss";

$body-background: #f1f1f1;

$body-white-1: #ffffff;
$body-white-2: #f6f6f6;
$body-white-3: #e9e9e9;
$body-white-4: #cacaca;

$body-gray-1: #b3b3b3;
$body-gray-2: #949494;
$body-gray-3: #767676;
$body-gray-4: #5f5f5f;

$body-black-1: #464650;
$body-black-2: #32323a;
$body-black-3: #212127;
$body-black-4: #121216;

$thm-green-soft: #f6ffed;
$thm-green-1: #5bb80f;
$thm-green-2: #469207;
$thm-green-3: #2e6600;

$thm-1: #1fb5ad;
$thm-2: #32d2c9;

$info-soft: #e7f7ff;
$info: #1790ff;
$info-dark: #0674db;

$warning-soft: #fffbe6;
$warning: #faad15;
$warning-dark: #da9100;

$danger-soft: #fcbcbe;
$danger: #ff4144;
$danger-dark: #cf0003;

$thm-gradient: linear-gradient(90deg, rgba(221, 227, 232, 1) 0%, rgba(255, 255, 255, 1) 100%);


/*#endregion */

/*#region Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

$body-fontFamily: 'Poppins',
sans-serif;
$body-fontFamily-only: 'Poppins';

/*#endregion */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: $body-white-4;
}

::-webkit-scrollbar-thumb {
  background: $body-gray-2;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

.ant-btn-primary {
  background: $thm-2;
  border-color: $thm-2;

  &:focus,
  &:hover {
    background: $thm-1;
    border-color: $thm-1;
  }
}

.ant-btn-default {

  &:hover,
  &:focus {
    color: $thm-2;
    border-color: $thm-2;
    background: #fff;
  }
}

.dx-datagrid-filter-panel {
  div {
    cursor: pointer;
    transition: .1s all ease-in-out;

    &:hover {

      .dx-icon-filter,
      .dx-datagrid-filter-panel-text {
        color: $thm-1 !important;
      }
    }
  }

  .dx-icon-filter,
  .dx-datagrid-filter-panel-text {
    color: $thm-2 !important;
    text-decoration: none !important;
  }
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  border-bottom: 1px solid $body-white-3;
}

.dx-datagrid .dx-column-lines>td {
  border-left: 1px solid $body-white-3;
  border-right: 1px solid $body-white-3;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 13px;
  font-weight: normal;
  background: $body-background;
  font-family: $body-fontFamily;
  overflow: hidden;
}

.c-main {
  width: 100%;
  min-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
  overflow-y: scroll;
  position: relative;

  &__widget {
    min-height: calc(100vh - 132px);
    height: calc(100vh - 132px);
  }
}

.c-content {
  width: calc(100% - 30px);
  padding: 15px;
  margin: 15px;
  background: $body-white-1;
  border-radius: 5px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
  position: relative;

  &__item {
    padding: 0 15px;
    background: $body-white-1;
    border-radius: 5px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
  }
}

.c-general {
  &__header {
    width: calc(100% + 30px);
    height: 55px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: -15px;
    padding: 10px 15px;
    margin-bottom: 20px;
    color: $body-white-1;
    background-color: $body-black-2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
  }
}

.c-margin {
  &__top {
    margin-top: 20px;
  }

  &__bottom {
    margin-bottom: 20px;
  }
}

.btn-grid{
  margin-left: 10px
}

.p-login {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-image: url(../img/loginImage.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  &__center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  &__logo {
    padding-bottom: 50px;

    img {
      width: 300px;
      height: auto;
      display: flex;
      filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
    }
  }

  &__form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      padding: 0 20px;

      &-name {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: $body-white-1;
      }

      &-password {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: $body-white-3;
      }

      &-input {
        width: 250px;
        height: 36px;
        padding: 0;

        i {
          padding-right: 10px;
        }
      }

      &-button {
        border-radius: 20px;
        padding: 0 34px;
        height: 36px;
      }
    }
  }

  &__footer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: $body-white-2;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
  }
}

.c-header {
  width: 100%;
  height: 70px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $body-white-1;
  background-image: url(../img/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 999;

  &__left {
    width: 250px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $thm-1;
    padding: 22px 20px;
    position: relative;

    &-logo {
      display: flex;
      padding-right: 8px;

      img {
        width: 170px;
        height: auto;
        display: flex;
        filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.1));

      }
    }

    &-open-menu {
      width: 32px;
      height: 32px;
      font-size: 1.4em;
      color: $body-white-1;
      position: absolute;
      right: -16px;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $thm-2;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__menu {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0 20px;

    &-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-item {
      display: flex;
      align-items: center;
      color: $body-black-1;

      a {
        font-weight: 500;
        color: $body-black-1;
      }

      &:not(:last-child):after {
        content: '';
        width: 1px;
        height: 30px;
        background-color: $thm-1;
        margin: 0 25px;
      }

      &-drop {
        padding: 26px 0;

        &-name {
          padding: 0 40px 0 10px;
        }

        &-lang {
          padding: 0 10px;
        }

        i {
          font-size: 1em;
        }
      }
    }

    &-search {
      width: fit-content;
      height: fit-content;
      position: relative;
      margin-right: -15px;

      &-btn {
        width: 50px;
        height: 50px;
        border-style: none;
        font-weight: bold;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 0;
        color: $body-gray-4;
        background-color: transparent;

        &:focus~.c-header__menu-search-input {
          width: 300px;
          border-radius: 0;
          background-color: transparent;
          border-bottom: 1px solid rgba(102, 102, 102, 0.5);
          transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
        }
      }

      &-input {
        height: 50px;
        width: 50px;
        border-style: none;
        padding: 10px 40px 10px 10px;
        outline: none;
        transition: all .5s ease-in-out;
        background-color: transparent;
        color: $body-gray-4;

        &::placeholder {
          color: $body-gray-3;
        }

        &:focus {
          width: 300px;
          border-radius: 0;
          background-color: transparent;
          border-bottom: 1px solid $body-gray-1;
        }
      }
    }
  }
}

.c-sider {
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 20px 0;
  background: $body-black-2;

  .ant-menu-item {
    margin: 0 !important;
  }

  .ant-menu-item span,
  .ant-menu-submenu-title span {
    font-size: .9em;
    font-weight: 300;
  }

  .ant-layout-sider-children {
    background: $body-black-2;
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: $body-black-2;
  }

  .ant-menu-submenu-open,
  .ant-menu-submenu-open>.ant-menu.ant-menu-sub {
    background-color: $body-black-3;
  }

  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover,
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $body-black-4;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin: 0;
  }
}

.c-page {
  width: 100%;
  height: auto;

  &__refresh{
    &-item{
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &__header {
    width: 100%;
    height: auto;
    padding: 15px;
    background-color: $body-white-1;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    &-title {
      font-size: 1em;
      font-weight: 700;
      color: $body-black-1;
    }

    &-filter {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-item {
        display: flex;
        align-items: center;

        &:not(:last-child):after {
          content: '';
          width: 2px;
          height: 30px;
          margin: 0 20px;
          background-color: $body-white-4;
          display: flex;
        }
      }

      &-date {
        &-name {
          padding-right: 15px;
          display: flex;
          color: $body-gray-2;
        }

        &-button {
          border-radius: 5px;
          background-color: $body-white-2;
          color: $body-black-1;

          span {
            font-size: 0.8em;
          }

          &:not(:last-child) {
            margin-right: 5px;
          }

          &--active {
            background-color: #32d2c9 !important;
          }
        }
      }
    }
  }
}

.alarm-defination-grid {
  .dx-datagrid-header-panel {
    display: none;
    visibility: hidden;
  }
}

.alarm-trigger-grid {
  .dx-icon-edit-button-addrow {
    display: none;
    visibility: hidden;
  }
}

.ant-tooltip-inner {
  font-size: .8em;
  border-radius: 5px;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-grid {
  width: 100%;
  height: 100%;
  // max-height: calc(100vh - 140px);
  // overflow: auto;

  .dx {
    &-datagrid {
      &-headers {
        min-height: 0;

        &-row {
          color: $body-white-1;
          background: $body-black-2;
        }
      }
    }

    &-header {
      min-height: 0;

      &-row {
        color: $body-black-1;
        background-color: $body-white-3;
      }
    }

    &-button {
      border-radius: 8px;
      background-color: $body-white-2;
      border-color: $body-white-2;
      transition: .2s all ease-in-out;
      color: $body-black-1;

      &:hover {
        background-color: $body-white-3;

      }

      i {
        font-size: 1em !important;
        color: $body-gray-3;
      }
    }

    &-form {
      &-group {
        &-caption {
          background: $body-black-1;
          background: $thm-gradient;
          width: 100%;
          display: flex;
          padding: 5px 10px;
          margin-bottom: -6px;
          font-size: .95em;
        }
      }
    }
  }
}

.c-country {
  display: flex;
  justify-content: space-between;

  &__drop {
    width: 300px;
  }

  &__region {
    width: calc(50% - 10px);
  }
}

.p-tree {
  width: 280px;
  margin: 15px;
  padding-bottom: 20px;
  background: $body-white-1;
  border-radius: 5px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
  flex: none;

  &__header {
    display: flex;
    align-items: center;

    &-title {
      width: 100%;
      max-width: 320px;
      display: flex;
      font-weight: 600;
    }

    &-select {
      width: 100%;
    }
  }

  &__menu {
    &-header {
      height: 55px;
      padding: 10px;
      margin-bottom: 20px;
      background-color: $body-black-2;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
      display: flex;

      button {
        margin: 0 5px;
        width: 19%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__content {
    padding: 0 15px 15px 15px;
    height: calc(100vh - 291px);
  }
}

.dx-treeview-item {
  .active {
    background-color: sandybrown;
  }
}

.c-edit {
  &__form {
    &-title {
      background: $body-black-1;
      background: $thm-gradient;
      width: 100%;
      display: flex;
      padding: 10px;
      margin-bottom: 15px;
      font-weight: 600;
    }
  }

  &__button {
    label {
      margin-right: 10px;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.ant-steps {
  padding: 15px;
}

.ant-steps-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.ant-steps-item-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.c-central {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__select {
    width: 100%;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.c-product {
  &__select {
    &-button {
      &-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  &__tree {
    display: flex;
  }
}

.c-button {
  &__margin-rl {
    margin: 0 8px;
  }

  &__margin-tb {
    margin: 8px 0;
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: $body-white-2;
  }
}

.c-alert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0;
  border: 1px solid $body-white-4;
  border-radius: 8px;
  overflow: hidden;
  background-color: $body-white-2;
  transition: .2s all ease-in-out;

  &:hover {
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.25);
  }

  &__success {
    background-color: $thm-green-soft;
    border: 1px solid $thm-green-1;

    .c-alert__icon {
      background-color: $thm-green-1;
    }

    .c-alert__button {
      color: $thm-green-1;
      border-color: $thm-green-1;

      &:hover {
        color: $thm-green-2;
        border-color: $thm-green-2;
      }
    }
  }

  &__danger {
    background-color: $danger-soft;
    border: 1px solid $danger;

    .c-alert__icon {
      background-color: $danger;
    }

    .c-alert__button {
      color: $danger;
      border-color: $danger;

      &:hover {
        color: $danger-dark;
        border-color: $danger-dark;
      }
    }
  }

  &__warning {
    background-color: $warning-soft;
    border: 1px solid $warning;

    .c-alert__icon {
      background-color: $warning;
    }

    .c-alert__button {
      color: $warning;
      border-color: $warning;

      &:hover {
        color: $warning-dark;
        border-color: $warning-dark;
      }
    }
  }

  &__info {
    background-color: $info-soft;
    border: 1px solid $info;

    .c-alert__icon {
      background-color: $info;
    }

    .c-alert__button {
      color: $info;
      border-color: $info;

      &:hover {
        color: $info-dark;
        border-color: $info-dark;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: stretch;
  }

  &__icon {
    width: 70px;
    height: auto;
    padding: 25px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $body-white-4;
    color: $body-white-1;
  }

  &__content {
    padding: 15px;

    &-title {
      display: flex;
      padding: 5px;
    }

    &-text {
      display: flex;
      padding: 5px;
    }
  }

  &__button {
    height: 100%;
    padding: 5px 10px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid $body-gray-2;
    color: $body-gray-2;
    cursor: pointer;
    transition: .2s all ease-in-out;

    &:hover {
      color: $body-black-1;
      border-color: $body-black-1;
    }
  }
}

.c-color {
  display: flex;
  align-items: center;

  &__view {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    margin-right: 15px;
  }

  &__code {
    color: $body-gray-3;
    font-weight: 600;
  }
}

.ant-drawer-body {
  flex-grow: inherit;
  height: calc(100% - 127px);
}

.ant-drawer-header {
  background-color: $thm-1;

  .ant-drawer-title {
    color: $body-white-1;
  }

  button {
    color: $body-white-1;
  }
}

.ant-drawer-content-wrapper {
  position: fixed;
  height: calc(100vh - 132px) !important;
}

.c-widget {
  width: 100%;
  min-height: calc(100vh - 132px);
  padding: 15px;
  position: relative;

  &__add {
    &-button {
      position: fixed;
      top: 140px;
      right: 20px;
    }
  }

  &__menu {
    position: absolute;

    &-content {
      position: relative;
    }

    &-bottom {
      width: 100%;
      padding: 20px;
      position: absolute;
      z-index: 9999;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      background-color: $body-white-1;
      box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
    }

    &-area {
      &-checkbox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &-item {
          width: calc(50% - 4px);
          padding: 5px;
          border: 1px solid $body-white-3;
          border-radius: 3px;
        }

        p {
          padding: 10px 0;
        }
      }

      &-select {
        margin-bottom: 10px;

        &-title {
          color: $body-gray-2;
          font-size: 0.9em;
        }
      }
    }
  }

  &__item {
    margin: 10px;
    background: $body-white-1;
    border-radius: 5px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &-header {
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $body-white-3;
      color: $body-black-1;

      &-title {
        padding: 10px 15px;
        cursor: move;

      }
    }

    &-remove {
      position: absolute !important;
      top: 3px;
      right: 5px;
      display: flex;
      cursor: pointer;
      border: none;
      background: transparent;
      z-index: 9999;
      padding: 12px 15px;
      pointer-events: all;

      &:hover {
        i {
          color: $danger;
        }
      }

      i {
        color: $body-black-1;
        transition: .1s all ease-in-out;
      }
    }

    &-content {
      width: 100%;
      height: calc(100% - 40px);
      display: block;
      padding: 10px 15px;
    }
  }

  &__container {
    width: 100%;
    min-height: 100%;
    position: relative;

    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
  font-size: 13px;
}

.full-width,
.chartwrapper {
  width: 100%;
  height: auto;
}

#devXchart {
  width: 100%;
  height: auto;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=") bottom right no-repeat;
  padding: 0 3px 3px 0;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.f-header {
  width: calc(100% - 20px);
  height: auto;
  display: flex;
  margin: 15px 10px;

  &__item {
    width: 100%;
    height: auto;
    display: grid;
    margin: 5px;
    background-color: $body-white-1;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

    &-small {
      max-width: 275px;
      min-width: 150px;
      padding: 20px;
    }

    &-big {
      display: flex;

      &-col {
        width: 25%;
        margin: 20px;

        &:not(:last-child) {
          border-right: 1px solid $body-white-4;
        }
      }
    }

    small {
      font-size: 1em;
      font-weight: 700;
      color: $body-gray-2;
    }

    strong {
      display: flex;
      font-size: 1.4em;
      padding: 5px 0;

      span {
        font-weight: 400;
      }
    }

  }
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  height: auto;
  overflow: hidden;
}

.c-browser {
  width: 100%;
  height: auto;

  &__top {
    width: 100%;
    height: 440px;
    display: flex;
    align-items: stretch;
  }

  &__tree {
    width: 280px;
    margin: 15px;
    background: $body-white-1;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
    flex: none;

    &-header {
      padding: 10px;
      background-color: $body-black-2;

      &-title {
        color: $body-white-1;
        margin-bottom: 10px;
        display: flex;
      }
    }

    &-menu {
      height: 320px;
      padding: 15px 0;
      overflow-y: auto;

      &-dot {
        margin-right: 5px;
        color: $danger-dark;
      }
    }
  }

  &__bottom {
    width: 100%;
  }

  &__graph {
    height: 100%;
    display: flex;

    &-tabs {
      width: 100%;
      height: auto;
    }
  }
}

.c-fleet {
  &__container {
    display: flex;
  }

  &__box {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    background-color: $body-gray-2;
    color: $body-white-1;
    border-radius: 3px;
    cursor: pointer;
  }
}

.c-alarm {
  &__tabs {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        transition: .1s all ease-in-out;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $info-soft !important;
        transition: .1s all ease-in-out;
      }
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: inherit;
    }

    .ant-tabs-tab:nth-child(1) {
      color: #4faf00;
    }

    .ant-tabs-tab:nth-child(2) {
      color: #ff0000;
    }

    .ant-tabs-tab:nth-child(1)[class$="ant-tabs-tab-active"] {
      color: $body-white-1 !important;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      background-color: #4faf00;
    }

    .ant-tabs-tab:nth-child(2)[class$="ant-tabs-tab-active"] {
      color: $body-white-1 !important;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      background-color: #ff0000;
    }
  }
}

.dx-datagrid-table {
  font-size: 13px;
}

.c-select {
  &__top {
    width: calc(100% - 20px);
    display: flex;
    flex: content;
    margin: 7px 7px 10px 7px;

    &-left {
      width: auto;
      height: 100%;
      display: flex;

      &-add {
        width: 100%;
      }
    }

    &-right {
      height: 100%;
      display: flex;
      padding: 15px;
      background: $body-white-1;
      border-radius: 5px;
      box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &__tree {
    width: 240px;
    margin: 10px;
    padding-bottom: 20px;
    background: $body-white-1;
    border-radius: 5px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);

    &-header {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      max-width: 320px;
      font-weight: 600;
      background-color: $body-white-4;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: $body-black-1;
    }

    &-menu {
      height: calc(100vh - 225px);
      overflow-y: auto;
      padding: 10px 5px 0 5px;
      font-size: 12px;

      span,
      p,
      b {
        font-size: 14px;
      }

      p {
        padding-top: 5px;
      }

      .dx-treeview-item-with-checkbox .dx-treeview-item {
        color: #333;
        padding-left: 27px;
      }

      .dx-checkbox-icon {
        width: 15px;
        height: 15px;
      }

      .dx-treeview-item {
        min-height: 25px;
        padding: 0;
      }

      .dx-treeview-item-content {
        margin-left: -3px;
      }

      .dx-treeview-toggle-item-visibility {
        height: 27px;
      }
    }
  }

  &__graph {
    width: 100%;
    height: 100%;

    .highcharts-container {
      width: 100% !important;
      height: 100% !important;

      .highcharts-root {
        width: 100%;
        height: 100%;
      }
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

#layout {
  width: 100%;
  height: calc(100vh - 170px);
  display: flex;
  overflow: hidden;

  .content {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 1rem;
  }
}

#main {
  width: 100%;
  flex-grow: 1;
}

#left {
  transition: width 0.4s;
  width: 0;
  display: flex;
  position: relative;

  .icon {
    position: absolute;
    height: 100%;
    right: -20px;
    padding: 20px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      background-color: $body-white-1;
      padding: 50px 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  @media (orientation: landscape) {
    &.open {
      width: auto;
    }
  }

  .sidebar {
    transition: transform 0.4s;

    @media (orientation: portrait) {
      width: 80vw;
    }

    @media (orientation: landscape) {
      // width: calc(1vw * 25);
    }
  }
}

#left {
  z-index: 5;

  .sidebar {
    &.closed {
      transform: translateX(-100%);
    }
  }
}

.highcharts-container {
  overflow: visible !important;
}


.MyChartTooltip {
  position: relative;
  z-index: 9999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: auto;
  max-height: 500px;
  min-width: 350px;
  cursor: pointer;
  //background-color: $body-black-3;
}

.MyChartTooltipHeader {
  position: relative;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  color: $body-white-1;
  background-color: rgba(19, 19, 22, 0.95);
}

.MyChartTooltipBody {
  position: relative;
  z-index: 9999;
  overflow-y: auto;
  height: auto;
  max-height: 470px;
  width: 100%;
  background-color: rgba(33, 33, 39, 0.95);
}

.MyChartTooltipRow {
  position: relative;
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: $body-white-1;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.MyChartTooltipRowIcon {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 5px;
  left: 5px;
}

.MyChartTooltipRowTitle {
  position: absolute;
  height: 20px;
  line-height: 20px;
  left: 30px;
}

.MyChartTooltipRowValue {
  position: absolute;
  height: 20px;
  line-height: 20px;
  right: 10px;
  font-weight: bold;
}

.c-day {

  &__sunset,
  &__sunrise {
    width: 25px;
    height: auto;
    display: flex;
  }
}