$body-white-1: #ffffff;
$body-white-2: #e8effc;
$body-white-3: #c6d3f0;
$body-white-4: #b8c2da;

$body-gray-1: #a9b1c4;
$body-gray-2: #8f97a8;
$body-gray-3: #737986;
$body-gray-4: #545964;

$body-black-1: #3b3e46;
$body-black-2: #222429;
$body-black-3: #000000;

$body-background: #f0f2f5;

$thm-green-soft: #f6ffed;
$thm-green-1: #52c41a;
$thm-green-2: #3ea50b;
$thm-green-3: #287700;
$thm-dark-1: #00386d;
$thm-dark-2: #001a33;
$thm-dark-3: #000f1d;

$info-soft: #e7f7ff;
$info: #1890ff;
$info-dark: #0971d3;

$warning-soft: #fffbe6;
$warning: #faad15;
$warning-dark: #da9100;

$danger-soft: #fcbcbe;
$danger: #ff4144;
$danger-dark: #cf0003;

.u-main {
  width: 100%;
  height: auto;
  overflow-y: auto;
}

.u-header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: $thm-green-2;

  &__logo {
    display: flex;

    img {
      width: 250px;
      height: auto;
      display: flex;
    }
  }

  &__menu {
    a {
      font-size: 15px;
      padding: 10px 20px;
      color: $body-white-1;
    }
  }
}

.u-status {
  width: calc(100% - 30px);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  background-color: $body-white-1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);


  &__info {
    display: flex;
    flex-direction: column;

    strong {
      font-size: 15px;
    }

    small {
      font-size: 14px;

      i {
        padding: 5px 10px;
        color: $info;
      }
    }

    p {
      font-size: 14px;
      color: $thm-green-2;

      i {
        padding-right: 10px;
      }
    }
  }

  &__weather {
    display: flex;
    font-size: 30px;
  }
}

.u-summary {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  padding: 20px 0;
  margin: 15px;
  background-color: $body-white-1;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 25px;

    &:not(:last-child) {
      border-right: 1px solid $body-white-4;
    }

    &-info {
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
        padding-right: 10px;
      }

      strong {
        font-size: 25px;
      }

      &-text {
        font-size: 14px;
        color: $body-gray-3;
      }
    }
  }
}

.u-view {
  width: calc(100% - 30px);
  height: auto;
  display: flex;
  margin: 15px;
  background-color: $body-white-1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

  &__container {
    width: 100%;
  }

  &__info {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      &.blue {
        color: $info;

        .u-view__info-item-icon {
          border: 1px solid $info;
        }
      }

      &.green {
        color: $thm-green-2;

        .u-view__info-item-icon {
          border: 1px solid $thm-green-2;
        }
      }

      &-icon {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $body-gray-4;
        border-radius: 100%;
        overflow: hidden;

        i {
          font-size: 30px;
        }
      }

      strong {
        font-size: 20px;
        padding-bottom: 3px;

        small {
          font-weight: 400;
        }
      }

      span {
        font-size: 14px;
      }
    }

    &-center {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      &-progress {
        display: grid;

        i {
          font-size: 50px;
          padding-bottom: 5px;
        }

        strong {
          font-size: 25px;

          small {
            font-size: 15px;
            font-weight: 400;
          }
        }

        small {
          font-size: 15px;
        }
      }
    }

    &-effect {
      width: 100%;
      height: 100px;
      margin: 25px 0;
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      position: relative;

      &-center {
        width: 2px;
        height: 100px;
        background-color: $body-gray-3;
      }

      &-left,
      &-right {
        width: calc(50% - 60px);
        height: 50px;
        position: relative;

        &:after {
          content: '';
          width: 30px;
          height: 50px;
          position: absolute;
          bottom: -50px;
        }
      }

      &-left {
        border-bottom-left-radius: 10px;
        border-left: 2px solid $body-gray-2;
        border-bottom: 2px solid $body-gray-2;

        &:after {
          right: -20px;
          border-top-right-radius: 10px;
          border-right: 2px solid $body-gray-2;
          border-top: 2px solid $body-gray-2;
        }
      }

      &-right {
        border-bottom-right-radius: 10px;
        border-right: 2px solid $body-gray-2;
        border-bottom: 2px solid $body-gray-2;

        &:after {
          left: -20px;
          border-top-left-radius: 10px;
          border-left: 2px solid $body-gray-2;
          border-top: 2px solid $body-gray-2;
        }
      }

      &-rotate & {
        &-left {
          margin-right: 20px;
        }

        &-right {
          margin-left: 20px;
        }
      }
    }
  }
}

.u-date {
  width: calc(100% - 30px);
  height: auto;
  display: grid;
  margin: 15px;
  background-color: $body-white-1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

  &__select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $thm-green-2;
    padding: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &-item {
      display: flex;

      a {
        display: flex;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 10px;
        font-weight: 600;
        transition: .2s all ease-in-out;
        color: $body-white-1;

        &:hover {
          background-color: $thm-green-3;
        }
      }

      &--active a {
        background-color: $thm-green-3;
      }

    }
  }

  &__calendar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $body-white-2;
    padding: 15px;

    &-datepicker {
      &.ant-picker.ant-picker-borderless {
        border-bottom: 1px solid $body-gray-1 !important;
      }

      .ant-picker-input>input::placeholder,
      span {
        color: $body-gray-4;
      }
    }

    &-weather {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.u-energy {
  width: calc(100% - 30px);
  height: auto;
  display: grid;
  margin: 15px;
  background-color: $body-white-1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      width: auto;
      margin: 0 15px;

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        position: relative;

        // &:after {
        //     content: '';
        //     width: 100%;
        //     height: 2px;
        //     position: absolute;
        //     background-color: $body-white-4;
        // }

        &.blue {
          color: $info;

          .u-view__info-item-icon {
            border: 1px solid $info;
          }
        }

        &.green {
          color: $thm-green-2;

          .u-view__info-item-icon {
            border: 1px solid $thm-green-2;
          }
        }

        &-icon {
          width: 60px;
          display: flex;
          justify-content: center;
          font-size: 30px;
          padding-right: 15px;
        }

        &-text {
          display: flex;
          flex-direction: column;

          strong {
            font-size: 18px;
            padding-bottom: 3px;
            white-space: nowrap;

            small {
              font-weight: 400;
            }
          }

          span {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }

      &-effect {
        width: calc(100% - 20px);
        padding: 1px;
        margin: 0 10px;
        background-color: $body-gray-1;
        position: relative;

        &:after,
        &:before {
          content: '';
          padding: 1px;
          position: absolute;
          left: 0;
          right: 0;
          background-color: $body-gray-1;
        }

        &:after {
          top: -55px;
        }

        &:before {
          bottom: -55px;
        }
      }
    }

    &-progress {
      display: grid;

      i {
        font-size: 50px;
        padding-bottom: 5px;
      }

      strong {
        font-size: 25px;

        small {
          font-size: 15px;
          font-weight: 400;
        }
      }

      small {
        font-size: 15px;
      }
    }
  }
}

.u-graphic {
  width: calc(100% - 30px);
  height: 100%;
  display: flex;
  margin: 15px;
  background-color: $body-white-1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.full-width,
.chartwrapper {
  width: 100%;
  height: auto;
}

#devXchart {
  width: 100%;
  height: auto;
}

.dxc.dxc-chart {
  width: 100%;
  display: block;
}