@media only screen and (max-width: 1366px) {

    body,
    html {
        font-size: 12px !important;
    }

    .dx-dropdowneditor.dx-dropdowneditor-field-clickable,
    .dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input,
    .dx-item-content,
    .dx-list-item-content,
    .dx-texteditor-input,
    .dx-widget,
    .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
        font-size: 12px !important;
    }

    .dx-datagrid {
        &-group-panel {
            font-size: 12px !important;
        }
    }

    .ant-menu,
    .ant-input,
    .ant-btn {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 1100px) {}

@media only screen and (max-width: 750px) {}